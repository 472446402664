<template>
  <div class="listCategoryChild">
    <div class="container-fluid container-edit pt-5 pb-4">
      <h1 class="text-center title-mobile pb-2">{{parent.name[$i18n.locale]}}</h1>
    </div>
    <div class="container-fluid container-mobile-content pt-3 pr-3 pl-3">

      <div class="row pr-5 pl-5 d-flex justify-content-center">

        <div class="container-fluid divider mb-4">
          <h4 class="title-mobile title-nothing">{{$t('categoriesSubtitle')}}</h4>
        </div>

        <div v-if="isEmptyCat" class="row col-12 d-flex justify-content-center">
          <h1 class="text-center title-mobile pt-2 pb-4">{{$t('categoryNoChildCategory')}}</h1>
        </div>

        <div class="card ml-5 mr-5 mb-5" v-for="(category, id) in categoriesChilds" :key="id" style="width: 20rem;">
          <a class="card-edit" :id="'c' + category.id" :href="'/category/edit/' + category.id">
            <i class="fas fa-pencil-alt"></i>
          </a>
          <b-tooltip :target="'c' + category.id" triggers="hover">
            <span>{{$t('edit')}}</span>
          </b-tooltip>
          <a :href="'/category/list/child/product/' + category.id">
            <img class="card-img-top category-img" :src="url + 'catalog/category/image/' + category.image" alt="Card image cap">
          </a>
          <div class="card-body">
            <div class="row card-category-content">
              <div class="col-9 align-items-center">
                <div>
                  <h5 class="card-title">{{category.name[$i18n.locale]}}</h5>
                </div>
                <div>
                  <h5 class="card-id">{{$t('categoryIDLabel')}} : {{category.id}} / {{$t('categorySortLabel')}} : {{category.sorting}}</h5>
                </div>
              </div>
              <div class="col-3 d-flex justify-content-center" v-on:click.prevent="editVisibility(category)" :id="'v' + category.id" :class="{ 'button-active':  category.visible, 'button-desactivate':  !category.visible }">
                <i v-if="category.visible" class="fas fa-eye" key="eye"></i>
                <i v-else class="fas fa-eye-slash" key="eyeslash"></i>
              </div>
              <b-tooltip :target="'v' + category.id" triggers="hover">
                <span v-if="category.visible">{{$t('visible')}}</span>
                <span v-else>{{$t('invisible')}}</span>
              </b-tooltip>
            </div>
          </div>
        </div>

         <div class="container-fluid divider mb-4">
          <h4 class="title-mobile title-nothing">{{$t('productSubtitle')}}</h4>
        </div>

        <div v-if="isEmptyProd" class="row col-12 d-flex justify-content-center">
          <h1 class="text-center title-mobile pt-2 pb-4">{{$t('categoryNoProduct')}}</h1>
        </div>
        
        <div class="row pr-5 pl-5 d-flex justify-content-center">
          <div class="card ml-5 mr-5 mb-5" v-for="(product, id) in products" :key="id" style="width: 20rem;">
            <a class="card-edit" :id="'p' + product.id" :href="'/product/edit/' + product.id">
              <i class="fas fa-pencil-alt"></i>
            </a>
            <b-tooltip :target="'p' + product.id" triggers="hover">
              <span>{{$t('edit')}}</span>
            </b-tooltip>
            <img class="card-img-top category-img" :src="url + 'catalog/product/image/' + product.image" alt="Card image cap">
            <div class="card-body">
              <div class="row card-category-content">
                <div class="col-12 align-items-center">
                  <div>
                    <h5 class="card-title">{{product.name[$i18n.locale]}}</h5>
                  </div>
                  <div>
                    <h5 class="card-id">{{$t('categoryIDLabel')}} : {{product.id}}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>
    <div class="back-fixed-bottom">
      <a class="container-back" :href="'/category/list'">
          <i class="fas fa-arrow-left"></i>
      </a>
    </div>
    <!-- <Setting :location="'category/list/child/' + $route.params.id" /> -->
  </div>
</template>

<script>

var utils = require('@/assets/js/utils');
import Setting from '@/components/Settings.vue';

export default {
  name : 'ListCategoryChild',
  components:{
    Setting,
  },
  data() {
    return {
      parent: {
        id: null,
        parentCategory: null,
        name: {fr: '', en: ''},
      },
      category: {
        name: {fr: '', en: ''},
        image: null,
        parentCategory: undefined,
        sorting: null,
        visible: true,
        imageBase64: null,
      },
      product: {
        name: {fr: '', en: ''},
        category: undefined,
        image: null,
      },
      products: [],
      categories: [],
      categoriesChilds: [],
      isEmptyCat: false,
      isEmptyProd: false,
      url: utils.getConfig().URL,
    }
  },
  methods: {
    editVisibility(category){   
      if(category.visible){
        var visibility = false;
      }
      else{
        var visibility = true;
      }
      var dataToInsert = { 
        id : category.id,
        name:[
          {"lang": "fr", "value": category.name.fr},
          {"lang": "en", "value": category.name.en},
        ],
        is_visible : visibility,
        parent_category: category.parentCategory,
        sorting: category.sorting
      }

      const formData = new FormData();
      formData.append('params', JSON.stringify(dataToInsert));

      let loader = this.$loading.show({
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: false,
        onCancel: this.onCancel,
        backgroundColor: '#000000',
        color: '#fff',
        opacity: 0.7,
        width: 100,
        height: 100,
      });

      this.$http.post(this.url + 'catalog/category/save', formData)
      .then(function(response){

        this.categoriesChilds.filter((element)=>{
          if(element.id == category.id){
            element.visible = visibility;
          }
        })
        loader.hide();
      })
      .catch(function (e){
          console.log(e);
      })
    },
  },
  mounted() {
    this.$http.get(utils.getConfig().URL + 'catalog/category/get/all/formated')
    .then((response) => {
      this.categories = response.data;

      if(this.$route.params.id != undefined){
        
        let loader = this.$loading.show({
          container: this.fullPage ? null : this.$refs.formContainer,
          canCancel: false,
          onCancel: this.onCancel,
          backgroundColor: '#000000',
          color: '#fff',
          opacity: 0.7,
          width: 100,
          height: 100,
        });

        this.$http.get(utils.getConfig().URL + 'catalog/category/get/id/' + this.$route.params.id)
        .then((response) => {
          if(response.data){
            this.parent = response.data;
            this.$http.get(utils.getConfig().URL + 'catalog/category/get/child/parent/id/' + this.$route.params.id)
            .then((response) => {
              if(response.data.length == 0){
                this.isEmptyCat = true;
              }
              else{
                this.categoriesChilds = response.data;
                this.categoriesChilds.sort((a, b) => a.sorting - b.sorting);
              }
            });
            loader.hide();
          }
          else{
            window.location = "/category/list";
          }
        });
      

        this.$http.get(utils.getConfig().URL + 'catalog/product/get/all/category/' + this.$route.params.id)
          .then((response) => {
            if(response.data){
              if(response.data.length == 0){
                this.isEmptyProd = true;
              }
              else{
                this.products = response.data;
                this.parent.id = this.products[0].category.id;
                this.parent.parentCategory = this.products[0].category.parentCategory;
                this.parent.name = this.products[0].category.name;
              }
              loader.hide();
            }
            else{
              window.location = "/category/list";
            }
          });
      }

    });

    

  }
}
</script>
<style scoped>
.divider{
  box-shadow: 0px 2px 0px #06a;
}
</style>


